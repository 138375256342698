<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-block d-md-none">
        <group-selector
          v-if="auth.checkPermission('view-all-groups')"
          class="transaction__group-selector"
        />
      </v-col>
    </v-row>
    <v-row class="transaction__overview text-center">
      <v-col
        v-if="auth.checkPermission('view-all-groups')"
        cols="12"
        sm="4"
        md="3"
        class="overview__col overview__col--period text-sm-left d-none d-md-block"
      >
        <v-menu close-on-click>
          <template v-slot:activator="{ on, attrs }">
            <h2 v-bind="attrs" v-on="on">
              <v-progress-circular
                v-if="!$store.state.selectedGroup"
                color="#37bd00"
                indeterminate
                size="16"
              />
              <span class="overview__group-name" v-else>
                {{ selectedGroupName }}
              </span>
              <v-icon>mdi-menu-down</v-icon>
            </h2>
          </template>
          <v-list>
            <v-list-item v-for="group in groupOptions" :key="group.value">
              <v-list-item-title
                class="overview__group-item"
                @click="selectGroup(group.value)"
              >
                {{ group.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <label>
          {{ $t('group_selector_placeholder') }}
        </label>
      </v-col>

      <v-col
        cols="12"
        sm="4"
        :md="auth.checkPermission('view-all-groups') ? 3 : 6"
        class="overview__col overview__col--period overview__col--has-separator text-sm-left"
      >
        <h2 @click="isPickerOpening = true">
          <span>{{ periodText }}</span>
          <v-icon>mdi-menu-down</v-icon>
        </h2>
        <label>
          {{ $t('transactions_month_select') }}
        </label>
        <v-date-picker
          class="overview__period-picker"
          v-if="isPickerOpening"
          v-model="currentPeriod"
          color="primary"
          type="month"
          :locale="$i18n.locale"
          @click:month="changePeriod"
        ></v-date-picker>
      </v-col>

      <v-col cols="4" sm="2" class="overview__col overview__col--has-separator">
        <h2><animated-num :value="info ? info.parkings : 0" /></h2>
        <label>{{ $t('g_parkings') }}</label>
      </v-col>

      <v-col cols="8" sm="4" md="2" class="overview__col">
        <h2>
          {{ info ? info.totalCost : 0 }}
        </h2>
        <label>{{ $t('transactions_total_price') }}</label>
      </v-col>

      <v-col cols="12" md="2" class="overview__col overview__col--button">
        <v-btn
          color="primary"
          class="overview__receipt-button"
          @click="downloadMonthlyReceipt"
        >
          <v-icon class="receipt-button__icon">mdi-file</v-icon>
          <span class="receipt-button__text">
            {{ $t('transactions_monthly_receipt') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

import auth from '@/auth'
import companyHelpers from '@/helpers/company'
import helpers from '@/helpers'

import AnimatedNum from '@/components/AnimatedNum'
import GroupSelector from '@/components/Groups/GroupSelector'

export default {
  name: 'TransactionsOverview',
  components: { AnimatedNum, GroupSelector },
  props: ['info'],
  data() {
    return {
      auth: auth,
      isPickerOpening: false,
      currentPeriod: moment(new Date()).format('YYYY-MM'),
      groupOptions: []
    }
  },
  computed: {
    periodText() {
      moment.locale(this.$i18n.locale)
      return moment(this.currentPeriod).format('MMMM YYYY')
    },
    currencySymbol() {
      return helpers.fetchStorage('currency')
    },
    selectedGroupName() {
      return this.$store.state.selectedGroup
        ? companyHelpers.getGroupNameById(
            this.$store.state.selectedGroup,
            this.$store.state.groupData
          )
        : ''
    }
  },
  watch: {
    info: {
      deep: true,
      handler() {
        this.$forceUpdate()
      }
    },
    '$store.state.groupData': {
      deep: true,
      handler() {
        this.buildGroupOptions()
      }
    }
  },
  methods: {
    async buildGroupOptions() {
      this.groupOptions = await companyHelpers.buildGroupOptions()
    },
    selectGroup(groupId) {
      this.$store.dispatch('setSelectedGroup', groupId)
    },
    changePeriod(value) {
      this.isPickerOpening = false
      this.currentPeriod = moment(value).format('YYYY-MM')

      this.$emit('change', value)
    },
    downloadMonthlyReceipt() {
      this.$emit('export')
    }
  },
  mounted() {
    this.buildGroupOptions()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.transaction__group-selector {
  @include full-width-mobile;
  @include full-width-tablet;
}

$overview-box-spacing: 24px;

.transaction__overview {
  position: relative;
  margin: $overview-box-spacing 0 0 0;
  border: 1px solid $color-line;
  border-radius: 4px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    background-color: rgba($color-line, 0.4);
    padding-bottom: $overview-box-spacing;
  }
}

.overview__col {
  position: relative;
  padding: $overview-box-spacing 0;
}

.overview__group-name {
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-bottom: -10px;
}

.overview__group-item {
  cursor: pointer;
}

.overview__col--period {
  // position: static;
  padding: 24px 0 24px 32px;
  cursor: pointer;
  white-space: initial;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 24px 0 0 0;

    &:after {
      content: ' ';
      display: block;
      width: calc(100% - #{$overview-box-spacing});
      height: 1px;
      background: #ccc;
      padding: 0 8px;
      margin: 24px auto -8px auto;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-only')} {
    padding: 24px 0 24px 24px;
  }
}

.overview__period-picker {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;

  @media #{map-get($display-breakpoints, 'sm-only')} {
    // a quick fix taking advantage of header and overview component position
    position: fixed;
    top: 104px;
    left: 24px;
  }
}

.overview__col--button {
  padding: 0;
  text-align: right;

  @media #{map-get($display-breakpoints, 'sm-only')} {
    padding: $overview-box-spacing;
    padding-top: 0;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 0 $overview-box-spacing / 2;
  }
}

.overview__receipt-button {
  @include text-button;
  width: 100%;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 48px !important;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  $button-width-md: 132px;

  .overview__col--has-separator {
    &:before {
      content: ' ';
      width: 1px;
      height: calc(100% - #{$overview-box-spacing * 2});
      background-color: #e5e5e5;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY($overview-box-spacing);
    }
  }

  .overview__receipt-button {
    width: $button-width-md;
    height: 100% !important;
    padding: 16px;
  }

  .receipt-button__icon {
    margin-bottom: 8px;
  }

  .receipt-button__text {
    // trick to make each word taking a line
    word-spacing: $button-width-md;
    width: $button-width-md;
  }
}
</style>

<style lang="scss">
@import '@/style/common';

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .overview__receipt-button .v-btn__content {
    flex-flow: column;
    white-space: normal;
  }
}
</style>
