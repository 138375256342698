<template>
  <div class="transactions__data">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="data"
      show-select
      hide-default-footer
      disable-pagination
      sort-by="start_date"
      disable-sort
      :mobile-breakpoint="mobileBreakpoint"
      @input="selectRow"
      @click:row="handleRowClick"
    >
      <template v-slot:item.parking_time="{ item }">
        <div v-html="item.parking_time"></div>
      </template>
      <template v-slot:item.no_vat_price="{ item }">
        {{ helpers.displayMoney(item.no_vat_price, item.currency) }}
      </template>
      <template v-slot:item.vat_price="{ item }">
        {{ helpers.displayMoney(item.vat_price, item.currency) }}
      </template>
      <template v-slot:item.total_price="{ item }">
        {{ helpers.displayMoney(item.total_price, item.currency) }}
      </template>

      <template v-slot:item.report_id="{ item }">
        <v-btn
          class="pkmn-button data__receipt-button"
          color="primary"
          text
          @click="download(item.report_id)"
          v-if="isMobile"
        >
          {{ $t('transactions_download') }}
        </v-btn>

        <v-icon
          class="data__receipt-button"
          :data-id="item.report_id"
          @click="download(item.report_id)"
          v-else
        >
          mdi-file
        </v-icon>
      </template>
    </v-data-table>

    <div class="data__info" v-if="totalSelected > 0">
      <v-container>
        <v-row>
          <v-col cols="6" sm="4" class="text-left">
            <v-btn
              class="pkmn-button"
              color="primary"
              outlined
              @click="deselectAll"
            >
              {{ $t('transactions_deselect_all') }}
            </v-btn>
          </v-col>
          <v-col cols="6" sm="8" class="info__export-wrap text-right">
            <div></div>
            <v-btn
              class="pkmn-button"
              color="primary"
              outlined
              @click="download(null)"
            >
              {{ $t('transactions_export') }} ({{ totalSelected }})
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import helpers from '@/helpers'

export default {
  name: 'TransactionsDataTable',
  props: ['data', 'period'],
  data() {
    return {
      headers: [
        { text: this.$t('g_name'), value: 'name' },
        { text: this.$t('g_cost_center'), value: 'cost_center' },
        { text: `${this.$t('g_cost_center')} 2`, value: 'company_cost_center' },
        { text: this.$t('tab_comment'), value: 'comment' },
        { text: this.$t('g_car'), value: 'car_num' },
        {
          text: `${this.$t('transactions_start')}/${this.$t('transactions_stop')}`,
          value: 'parking_time'
        },
        { text: this.$t('price_without_vat'), value: 'no_vat_price' },
        { text: this.$t('vat'), value: 'vat_price' },
        { text: this.$t('transactions_price'), value: 'total_price' },
        {
          text: this.$t('transactions_receipt'),
          value: 'report_id',
          align: 'center',
          sortable: false
        }
      ],
      totalSelected: 0,
      mobileBreakpoint: 769, // screen width in pixel to switch to mobile view
      helpers: helpers
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    /**
     * Handle row selection
     * Show bottom info bar if there are selections
     * Update bottom info bar info
     */
    selectRow(selectedRows) {
      this.totalSelected = selectedRows.length
    },
    /**
     * Deselect all rows
     */
    deselectAll() {
      this.$refs.table.selection = {}
    },
    /**
     * Select row on click
     */
    handleRowClick(item, row) {
      row.select(!row.isSelected)
    },
    /**
     * Handle transaction download
     * passing null means exporting all selected transactions
     */
    async download(reportId) {
      let ids = [reportId]

      // generate the list of ids to process
      if (!reportId) {
        ids = Object.values(this.$refs.table.selection).map(
          (row) => row.report_id
        )
      }

      const parkingIds = []
      const nonParkingTypes = []
      // arrange the ids into two types, int ids are for parkings, string ids are for non-parking types
      ids.forEach((id) => {
        if (isNaN(id)) {
          nonParkingTypes.push(id)
        } else {
          parkingIds.push(id)
        }
      })

      const args = {}

      if (parkingIds.length > 0) {
        args.list_ids = parkingIds.join(',')
      }

      if (nonParkingTypes.length > 0) {
        args.list_types = nonParkingTypes.join(',')
      }

      this.$emit('export', args)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.transactions__data {
  margin: 40px 0;
}

.data__title {
  padding-left: 18px;
  margin-bottom: 8px;
}

.data__receipt-button {
  color: $color-line;
  cursor: pointer;

  .v-data-table__selected & {
    color: $color-brand;
  }

  &:hover {
    color: $color-brand-hover;
  }

  &:focus:before {
    opacity: 1 !important;
  }
}

.data__info {
  position: fixed;
  width: 100vw;
  background-color: #fff;
  z-index: 4;
  left: 0;
  bottom: 0;
  box-shadow: 0px -1px 28px rgba(0, 0, 0, 0.13);
}

.data__info .container {
  padding: 0 12px;
  @include set-container-width;
}

.export__text {
  padding-right: 24px;
  align-self: center;

  &--warning {
    color: $color-warning;
  }
}
</style>

<style lang="scss">
.transactions__data {
  .v-data-table-header-mobile {
    display: none; // @todo: come back to this when sort feature and export feature enabled
  }

  .v-data-table__mobile-row:last-child {
    padding-bottom: 4px;
  }
}
</style>
